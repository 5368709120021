<template>
    <div class="wrapper">
      <br/>
      <div class="filtro-wrapper">
        <div class="linha" v-if="!firstLoad">
          <v-select
            outlined
            class="select"
            hide-details
            label="Operador"
            :items="autocomplete.operador"
            v-model="filters.pessoa"
            :returnObject="false"
            item-text="pesnome"
            item-value="pesidpessoa"
          />
          <v-select
            outlined
            class="select"
            hide-details
            label="Propriedade"
            :items="autocomplete.propriedade"
            v-model="filters.propriedade"
            :returnObject="false"
            item-text="pronome"
            item-value="proidpropriedade"
          />
          <br/>
        </div>
        <div class="linha">
          <core-date-picker-field
            v-model="filters.createdOn"
            form
            hide-details
            label="Data"/>           
          <v-btn color="primary" class="btn" @click="$_loadFilters">
            Filtrar
          </v-btn>
        </div>
      </div>

      <div class="linha-botao">
          <v-btn
          height="45"
          color="green botaoinferior"
          @click="$_lancarAtividade"
          :disabled="!(filters.pessoa && filters.propriedade)">
              {{labelBotao}}
          </v-btn>
      </div>
      <!-- <div class="semapontamento" v-if="!data.length && !loading" >
          <v-icon x-large>
            mdi-account-clock
          </v-icon> 
          <div>
            Não existem atividades no periodo escolhido.
          </div>
      </div> -->
      <div class="card-atividade-wrapper" v-if="!loading">
        <div v-for="item in data" :key="item.id" ref="card-atividade" class="card-atividade">
            <!-- <app-card-apontamento :value="item" @innerClick="$_onClickCard" class="card">
            </app-card-apontamento> -->
            <app-card-apontamento 
            :value="item" class="card"
            @onClickEditar="$_editarAtividade"
            @onClickReplicar="$_replicarAtividade">
            </app-card-apontamento>
        </div>
      </div>
    </div>
</template>

<script>
import AppCardApontamento from '../../components/app/card/apontamento/Index.vue';
// import BtnAutoComplete from "@/components/core/btn-auto-complete/Index.vue";
import CoreDatePickerField from '@/components/core/date-picker-field/Index.vue';
import { ROUTE_AP_CADASTRO } from '../../constants/ROUTES';
import { LANCAMENTO_STORAGE, ROTA_STORAGE } from '@/constants/STORAGE';
import { LANCAMENTO_QUERIES } from '@/constants/QUERY_FILTERS';
import db from '@/plugins/dexie';
import deepEquals from '@/utils/deep-equals';
import { getQueryValue, setQueryValue } from '@/services/query-string';
import geraData from '@/utils/geradata';
import sincronizaLocal from '@/utils/ws/envialocal'
// import geral from '@/utils/ws/routes/core/geral'
//import { SetupWebSocket } from '@/utils/ws/websocket';

function getSessionStorage(key, defaultValue) {
  return sessionStorage.getItem(key) ?? defaultValue;
}

export default {
    name: "ViewApontamento",
    components:{
        AppCardApontamento,
        // BtnAutoComplete,
        CoreDatePickerField,
    },

    data(){
        return{
            data: [],
            loading: false,
            firstLoad: true,
            labelBotao: 'Novo',
            filters: {
              createdFrom: getSessionStorage(LANCAMENTO_STORAGE.createdFrom, null),
              createdBefore: getSessionStorage(LANCAMENTO_STORAGE.createdBefore, null),
              createdOn: geraData(),
              
              pessoa: null,
              propriedade: null,
            },
            autocomplete: {
              operador: null,
              propriedade: null,
            }
        }
    },
    methods: {

      async $_setupAutoComplete() {
        this.autocomplete.operador = await db.pessoa.where({ pespessoafuncionario: 'S'}).toArray();
        this.autocomplete.propriedade = await db.propriedade.toArray();
        this.firstLoad = false;
      },

      async $_load() {
        if (!this.filters.pessoa || !this.filters.propriedade){
          this.data = [];
          return;
        }
        this.loading = true;
        try {
          this.data = await db.lancamentoatividade.where('laadatacriacao').between(
            geraData(this.filters.createdFrom), geraData(`${this.filters.createdBefore}T23:59:00`)
          );
          console.log(this.data, 'data');
          if (this.filters.pessoa) {
            this.data = this.data.and(item => item.laaidoperador == this.filters.pessoa);
          }
          if (this.filters.propriedade) {
            this.data = this.data.and(item => item.laaidpropriedade == this.filters.propriedade);
          }
          this.data = await this.data.toArray();
          this.data = await Promise.all(this.data.map(async (item) => {
            return {
              ...item,
              operador: await db.pessoa.where({ pesidpessoa: item.laaidoperador }).first(),
              atividade: await db.atividadeabastecimento.where({ ataidatividadeabastecimento: item.laaidatividade }).first(),
              talhao: await db.talhao.where({ talidtalhao: item.laaidtalhao }).first(),
              propriedade: await db.propriedade.where({ proidpropriedade: item.laaidpropriedade }).first(),
            }
          }));
        } catch (err) {
          console.log(err);
        } finally {
          this.loading = false;
        }
      },
      
      async $_onClickCard(idCard){
        sessionStorage.setItem(LANCAMENTO_STORAGE.idlancamento, idCard);
        this.$router.replace({
          ...this.$route,
          name: ROUTE_AP_CADASTRO.name,
        })
      },

      async $_lancarAtividade() {
        sessionStorage.setItem(ROTA_STORAGE.cadastro, 'atividade');
        sessionStorage.removeItem(LANCAMENTO_STORAGE.idlancamento);
        sessionStorage.setItem(LANCAMENTO_STORAGE.formulario, JSON.stringify({
          laaidoperador: this.filters.pessoa,
          laaidpropriedade: this.filters.propriedade,
        }));
        sessionStorage.setItem(LANCAMENTO_STORAGE.filtropropriedade, this.filters.propriedade);
        sessionStorage.setItem(LANCAMENTO_STORAGE.filtropessoa, this.filters.pessoa);
        this.$router.replace({
          ...this.$route,
          name: ROUTE_AP_CADASTRO.name,
        });
      },

      async $_editarAtividade(id) {
        sessionStorage.setItem(LANCAMENTO_STORAGE.idlancamento, id);
        sessionStorage.setItem(LANCAMENTO_STORAGE.formulario, JSON.stringify({
          laaidoperador: this.filters.pessoa,
          laaidpropriedade: this.filters.propriedade,
        }));
        sessionStorage.setItem(LANCAMENTO_STORAGE.filtropropriedade, this.filters.propriedade);
        sessionStorage.setItem(LANCAMENTO_STORAGE.filtropessoa, this.filters.pessoa);
        this.$router.replace({
          ...this.$route,
          name: ROUTE_AP_CADASTRO.name,
        });
      },

      async $_replicarAtividade(id) {
        sessionStorage.setItem(LANCAMENTO_STORAGE.idlancamento, id);
        sessionStorage.setItem(LANCAMENTO_STORAGE.replicado, true);
        sessionStorage.setItem(LANCAMENTO_STORAGE.formulario, JSON.stringify({
          laaidoperador: this.filters.pessoa,
          laaidpropriedade: this.filters.propriedade,
        }));
        sessionStorage.setItem(LANCAMENTO_STORAGE.filtropropriedade, this.filters.propriedade);
        sessionStorage.setItem(LANCAMENTO_STORAGE.filtropessoa, this.filters.pessoa);
        this.$router.replace({
          ...this.$route,
          name: ROUTE_AP_CADASTRO.name,
        });
      },

      async $_loadFilters() {
        setQueryValue(LANCAMENTO_QUERIES.propriedade, this.filters.propriedade);
        setQueryValue(LANCAMENTO_QUERIES.pessoa, this.filters.pessoa);
        if (this.filters.createdOn) {
          setQueryValue(LANCAMENTO_QUERIES.createdBefore, geraData(this.filters.createdOn, 'YYYY-MM-DD'));
          setQueryValue(LANCAMENTO_QUERIES.createdFrom, geraData(this.filters.createdOn, 'YYYY-MM-DD'));
          // sessionStorage.setItem(LANCAMENTO_STORAGE.createdFrom, geraData(this.filters.createdOn, 'YYYY-MM-DD'));
          // sessionStorage.setItem(LANCAMENTO_STORAGE.createdBefore, geraData(this.filters.createdOn, 'YYYY-MM-DD'));
        }
      }
    },
    watch: {
    $route: {
      deep: true,
      immediate: true,
      handler() {

        const filters = {
          createdFrom: getQueryValue(LANCAMENTO_QUERIES.createdFrom, this.filters.createdFrom),
          createdBefore: getQueryValue(
            LANCAMENTO_QUERIES.createdBefore,
            this.filters.createdBefore,
          ),
          pessoa: getQueryValue(
            LANCAMENTO_QUERIES.pessoa,
            null,
          ),
          propriedade: getQueryValue(
            LANCAMENTO_QUERIES.propriedade,
            null,
          ),
        };

        if (!deepEquals(this.filters, filters)) {
          this.filters.createdFrom = filters.createdFrom;
          this.filters.createdBefore = filters.createdBefore;
          this.filters.pessoa = Number(filters.pessoa);
          this.filters.propriedade = Number(filters.propriedade);

          this.$_load();   
        }
      },
    },

      options: {
        deep: true,
        handler() {
          setQueryValue(LANCAMENTO_QUERIES.createdFrom, this.filters.createdFrom);
          setQueryValue(LANCAMENTO_QUERIES.createdBefore, this.filters.createdBefore);
          setQueryValue(LANCAMENTO_QUERIES.pessoa, this.filters.pessoa);
          setQueryValue(LANCAMENTO_QUERIES.propriedade, this.filters.propriedade);
        },
      },
    },

    created(){
        sincronizaLocal();
        this.$_setupAutoComplete();
        setTimeout(() => {
          if (this.filters.createdFrom !== this.filters.createdBefore) {
            this.filters.createdOn = geraData();
          } else {
            this.filters.createdOn = getSessionStorage(LANCAMENTO_STORAGE.createdFrom, geraData());
          }
          this.filters.pessoa = Number(getSessionStorage(LANCAMENTO_STORAGE.filtropessoa, null));
          this.filters.propriedade = Number(getSessionStorage(LANCAMENTO_STORAGE.filtropropriedade, null));
          sessionStorage.removeItem(LANCAMENTO_STORAGE.filtropessoa);
          sessionStorage.removeItem(LANCAMENTO_STORAGE.filtropropriedade);
          if (!this.filters.pessoa) {
            setQueryValue('pessoa', null);
          }
          if (!this.filters.propriedade) {
            setQueryValue('propriedade', null);
          }
        })
        this.labelBotao = (sessionStorage.getItem(LANCAMENTO_STORAGE.formulario)) ? 'Retomar' : 'Novo';
    },
}
</script>

<style scoped>
  .filtro-wrapper {
    max-width: 450px;
    margin-inline: auto;
  }
  .linha {
    display: flex;
  }
  .linha > .btn {
    transform: translateY(6px);
  }
  .linha > * {
    margin: 5px;
  }
  .semapontamento {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 75px;
    padding: 25px;
  }
  .semapontamento > div {
    margin-top: 20px;
    width: 200px;
  }
  .botaoinferior {
    color:white;
  }
  .linha-botao {
    text-align: center;
    width: 100%;
    margin-top: 20px;
  }

  .card {
    padding: 3px;
    margin: 15px 10px 0px 10px
  }

  .card-atividade-wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-inline: auto;
    max-width: 450px;
  }
  .card-atividade {
    width: 100%;
  }

  :deep(.v-select.v-input .v-input__control .v-input__slot) {
    background-color: white !important;
  } 

  /* @media screen and (min-width: 750px) {
      .card-atividade-wrapper {
        max-width: 900px;
      }
      .card-atividade {
        width: 50%;
      }
  } */
</style>